<template>
    <div>

        <el-tag :key="i" v-for="(val,key,i) in this.manInfo" closable :disable-transitions=" false" @click="manInfoEdit(key,val)"
                @close="manInfoDelete(key)" style="margin-right: 10px;">
            {{key}}:{{val}}
        </el-tag>
        <el-button size="small" type="success" @click="manInfoNew()">+</el-button>
        <el-dialog :title="manInfoDialogParam.title" :visible.sync="manInfoDialogParam.visible" :width="manInfoDialogParam.width"
                   :close-on-click-modal='false' append-to-body>
            <el-form :model="manInfoData" label-width="100px" size="small" ref="manInfoForm" :rules="manInfoDataRules">
                <el-form-item label="键名(key)" prop="key">
                    <el-input v-model="manInfoData.key" placeholder="请输入英文键名(key),重复的键名会覆盖"></el-input>
                </el-form-item>
                <el-form-item label="值(value)" prop="value">
                    <el-input v-model="manInfoData.value" placeholder="请输入值(value)"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="manInfoDialogParam.visible = false">取 消</el-button>
        <el-button @click="manInfoPush()">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
    export default {
        props: { jsonInfo: { type: Object, required: true } },
        data () {
            return {
                manInfo: this.jsonInfo,
                /*-------------------------------------------------json manInfo 参数-----------------------------------------------*/
                manInfoDialogParam: {
                    visible: false,
                    title: '新建',
                    width: '50%',
                    top: '15vh',
                },
                /*--json 字段参数--*/
                manInfoRawData: {
                    key: '',
                    value: '',
                },
                manInfoData: {
                    key: '',
                    value: '',
                },
                manInfoDataRules:
                        {
                            key: [required],
                            value: [required],
                        },
                exitKey: '',//修改时原来的key
            };
        },
        methods: {
            /*-------------------------------------------------json manInfo 方法-----------------------------------------------*/
            manInfoNew () {
                this.manInfoData = JSON.parse(JSON.stringify(this.manInfoRawData))//重置
                this.manInfoDialogParam.title = "新建";
                this.manInfoDialogParam.visible = true;
            },
            manInfoPush () {
                this.$refs.manInfoForm.validate((valid) => {
                if (valid) {
                if (!this.exitKey) //如果存在exitKey,表示是修改,原来的key可能会被改了,这时候需要判断是否改过exitKey
                {
                this.$set(this.manInfo, this.manInfoData.key, this.manInfoData.value);
                }
                else if (this.exitKey == this.manInfoData.key) {
                this.$set(this.manInfo, this.manInfoData.key, this.manInfoData.value);
                this.exitKey = '';
                }
                else {
                this.$delete(this.manInfo, this.exitKey);
                this.$set(this.manInfo, this.manInfoData.key, this.manInfoData.value);
                this.exitKey = '';
                }
                this.manInfoDialogParam.visible = false;//不显示
                this.$emit('success', this.manInfo);
                }
                });
            },
            manInfoDelete (key) {
                this.$delete(this.manInfo, key);
                this.$emit('success', this.manInfo);
            },
            manInfoEdit (key, val) {
                this.exitKey = JSON.parse(JSON.stringify(key));
                console.log(this.exitKey);
                this.manInfoData.key = key;
                this.manInfoData.value = val;
                this.manInfoDialogParam.title = "修改";
                this.manInfoDialogParam.visible = true;
            },
        },
        filters: {},
        watch: {
            jsonInfo: function () {
                this.manInfo = this.jsonInfo;
            }
        },
        mounted () {
        },
    }
</script>
