import { render, staticRenderFns } from "./JsonInfoEvalInfo.vue?vue&type=template&id=63d93954&"
import script from "./JsonInfoEvalInfo.vue?vue&type=script&lang=js&"
export * from "./JsonInfoEvalInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports