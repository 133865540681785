<template>
    <div>

        <el-tag :key="i" v-for="(val,key,i) in this.quesInfo" closable :disable-transitions=" false" @click="quesInfoEdit(key,val)"
                @close="quesInfoDelete(key)" style="margin-right: 10px;">
            {{key}}:{{val}}
        </el-tag>
        <el-button size="small" type="success" @click="quesInfoNew()">+</el-button>
        <el-dialog :title="quesInfoDialogParam.title" :visible.sync="quesInfoDialogParam.visible" :width="quesInfoDialogParam.width"
                   :close-on-click-modal='false' append-to-body>
            <el-form :model="quesInfoData" label-width="100px" size="small" ref="quesInfoForm" :rules="quesInfoDataRules">
                <el-form-item label="键名(key)" prop="key">
                    <el-input v-model="quesInfoData.key" placeholder="请输入英文键名(key),重复的键名会覆盖"></el-input>
                </el-form-item>
                <el-form-item label="值(value)" prop="value">
                    <el-input v-model="quesInfoData.value" placeholder="请输入值(value)"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="quesInfoDialogParam.visible = false">取 消</el-button>
        <el-button @click="quesInfoPush()">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
    import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
    export default {
        props: { jsonInfo: { type: Object, required: true } },
        data () {
            return {
                quesInfo: this.jsonInfo,
                /*-------------------------------------------------json quesInfo 参数-----------------------------------------------*/
                quesInfoDialogParam: {
                    visible: false,
                    title: '新建',
                    width: '50%',
                    top: '15vh',
                },
                /*--json 字段参数--*/
                quesInfoRawData: {
                    key: '',
                    value: '',
                },
                quesInfoData: {
                    key: '',
                    value: '',
                },
                quesInfoDataRules:
                        {
                            key: [required],
                            value: [required],
                        },
                exitKey: '',//修改时原来的key
            };
        },
        methods: {
            /*-------------------------------------------------json quesInfo 方法-----------------------------------------------*/
            quesInfoNew () {
                this.quesInfoData = JSON.parse(JSON.stringify(this.quesInfoRawData))//重置
                this.quesInfoDialogParam.title = "新建";
                this.quesInfoDialogParam.visible = true;
            },
            quesInfoPush () {
                this.$refs.quesInfoForm.validate((valid) => {
                if (valid) {
                if (!this.exitKey) //如果存在exitKey,表示是修改,原来的key可能会被改了,这时候需要判断是否改过exitKey
                {
                this.$set(this.quesInfo, this.quesInfoData.key, this.quesInfoData.value);
                }
                else if (this.exitKey == this.quesInfoData.key) {
                this.$set(this.quesInfo, this.quesInfoData.key, this.quesInfoData.value);
                this.exitKey = '';
                }
                else {
                this.$delete(this.quesInfo, this.exitKey);
                this.$set(this.quesInfo, this.quesInfoData.key, this.quesInfoData.value);
                this.exitKey = '';
                }
                this.quesInfoDialogParam.visible = false;//不显示
                this.$emit('success', this.quesInfo);
                }
                });
            },
            quesInfoDelete (key) {
                this.$delete(this.quesInfo, key);
                this.$emit('success', this.quesInfo);
            },
            quesInfoEdit (key, val) {
                this.exitKey = JSON.parse(JSON.stringify(key));
                console.log(this.exitKey);
                this.quesInfoData.key = key;
                this.quesInfoData.value = val;
                this.quesInfoDialogParam.title = "修改";
                this.quesInfoDialogParam.visible = true;
            },
        },
        filters: {},
        watch: {
            jsonInfo: function () {
                this.quesInfo = this.jsonInfo;
            }
        },
        mounted () {
        },
    }
</script>
