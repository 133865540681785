<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="18">
        <el-button-group>
          <!-- <el-button @click="formDataNew" type="primary">新建</el-button> -->
          <!-- <el-button @click="formDataModify" type="primary">修改</el-button> -->
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>

    <el-table ref="questionTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick"
      :header-cell-class-name="addClass">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="manInfo" label="问卷人档案(info)">
        <el-table-column prop="manInfo.patientName" label="患者姓名" width="100"></el-table-column>
        <el-table-column prop="manInfo.patientIDCard" label="身份证号" width="200"></el-table-column>
        <el-table-column prop="manInfo.patientGender" label="性别" width="80"></el-table-column>
        <el-table-column prop="manInfo.patientAge" label="年龄" width="60" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientMobile" label="手机号" width="180"></el-table-column>
        <el-table-column prop="manInfo.hospitalName" label="就诊医院" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.hospitalNo" label="就诊医院登记号" width="130"></el-table-column>
        <el-table-column prop="manInfo.patientProfession" label="职业" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientNation" label="民族" width="80"></el-table-column>
        <el-table-column prop="manInfo.patientRegion" label="长期居住地" width="220" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientRegionDetail" label="居住" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientEducation" label="最高学历" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientProof" label="可支配收入" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="manInfo.patientSymptom" label="症状" width="120" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="manInfo.patientIllness" label="确诊" width="120"></el-table-column> -->
      </el-table-column>

      <el-table-column header-align="center" prop="quesInfo" label="问卷结果(info)">
        <el-table-column v-if="tableParam.askType=='urine'" prop="quesInfo.family" label="患者家族史信息" header-align="center">
          <el-table-column prop="quesInfo.family.familyLv1IllnessHistory" label="一级亲属是否患有膀胱癌,输尿管癌,肾盂癌" width="180" header-align="center"></el-table-column>
          <el-table-column prop="quesInfo.family.familyLv2IllnessHistory" label="二级亲属是否患有膀胱癌,输尿管癌,肾盂癌" width="180" header-align="center"></el-table-column>
          <el-table-column prop="quesInfo.family.familyOtherIllness" label="一级和二级亲属是否患有其他恶性肿瘤" width="180" header-align="center"></el-table-column>
          <el-table-column prop="quesInfo.family.familyOtherIllnessName" label="一级和二级亲属其他恶性肿瘤名称" width="150" header-align="center"></el-table-column>
          <el-table-column prop="quesInfo.family.patientOtherIllness" label="本人是否同时患有其他恶性肿瘤" width="150" header-align="center"></el-table-column>
          <el-table-column prop="quesInfo.family.patientOtherIllnessName" label="本人其他恶性肿瘤名称" width="100" header-align="center"></el-table-column>
        </el-table-column>
        <el-table-column v-if="tableParam.askType=='urine'" prop="quesInfo.smokeandothers" label="吸烟史及其他" header-align="center">

          <el-table-column prop="quesInfo.smokeandothers.smokeStatus" label="是否吸烟" width="180" header-align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.smokeCount" label="吸烟量" width="100" header-align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.smokeCut" label="戒烟时长" width="200"></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.dangerTouch" label="是否会长期接触工业化学产品? (比如芳香胺、多环芳烃、氯化碳氢化合物)" width="300" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.reInfection" label="是否有慢性泌尿道感染?(经医院尿液分析确诊的泌尿道感染，且病程>=3个月)" width="300" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.lithiasis" label="是否超过6个月以上的泌尿结石病史" width="160" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.lithiasisType1" label="泌尿结石类型" width="180" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.lithiasisType2" label="泌尿结石治疗情况" width="180" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.chineseMedic" label="是否长期服用中药?" width="100" header-align="center"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="quesInfo.smokeandothers.edibleCount" label="食用鱼腥草(折耳根)次数" width="200" header-align="center"
            show-overflow-tooltip></el-table-column>

        </el-table-column>
        <el-table-column v-if="tableParam.askType=='urine'" prop="quesInfo.cure" label="治疗方案" header-align="center">
          <el-table-column prop="quesInfo.cure.cure1" label="治疗方案1" header-align="center" width="200"></el-table-column>
          <el-table-column prop="quesInfo.cure.cure2" label="治疗方案2" header-align="center" width="200"></el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="createTime" label="问卷时间" width="180" header-align="center"></el-table-column>
      <el-table-column header-align="center" type="createTime" width="40"></el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="questionFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="编号" prop="uuid">
          <el-input v-model="formData.uuid" placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item label="问卷人档案(info)" prop="manInfo">
          <json-info-man-info :jsonInfo="formData.manInfo" @success="manInfoSuccess"></json-info-man-info>
        </el-form-item>
        <el-form-item label="问卷结果(info)" prop="quesInfo">
          <json-info-ques-info :jsonInfo="formData.quesInfo" @success="quesInfoSuccess"></json-info-ques-info>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="uuid" label="UUID"></el-table-column>
        <el-table-column prop="manInfo.patientName" label="患者姓名" width="100"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<style>
.cell1 {
  background-color: #409eff !important;
  color: #ffffff;
}
.cell2 {
  background-color: #67c23a !important;
  color: #ffffff;
}
.cell3 {
  background-color: #e6a23c !important;
  color: #ffffff;
}
.cell4 {
  background-color: #f56c6c !important;
  color: #ffffff;
}
.cell5 {
  background-color: #67c23a !important;
  color: #ffffff;
}
</style>
<script>
import JsonInfoManInfo from '@/components/question/JsonInfoManInfo.vue';
import JsonInfoQuesInfo from '@/components/question/JsonInfoQuesInfo.vue';
import { getTableData, doNew, doModify, doDelete, } from '@/api/wbkj/Question'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { JsonInfoQuesInfo, JsonInfoManInfo },
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '未知'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'askType': '',
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '30%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        uuid: '',
        askType: '',
        manInfo: {},
        quesInfo: {},

      },
      formDialogRules:
      {
        uuid: [required],//如果非必填,请去掉,如有其他验证,自行在formRules参考或新增
        askType: [required],//如果非必填,请去掉,如有其他验证,自行在formRules参考或新增
        manInfo: [required],//如果非必填,请去掉,如有其他验证,自行在formRules参考或新增
        quesInfo: [required],//如果非必填,请去掉,如有其他验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    addClass ({ row, column, rowIndex, columnIndex }) {
      if ([2].includes(columnIndex) && [0].includes(rowIndex)) {
        return 'cell2';
      }
      if ([3].includes(columnIndex) && [0].includes(rowIndex)) {
        return 'cell1';
      }
      if ([14].includes(columnIndex) && [1].includes(rowIndex)) {
        return 'cell3';
      }
      if ([15].includes(columnIndex) && [1].includes(rowIndex)) {
        return 'cell4';
      }
      if ([16].includes(columnIndex) && [1].includes(rowIndex)) {
        return 'cell5';
      }
    },
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.questionFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.manInfo = JSON.stringify(this.formData.manInfo);//由于MysqlJson的原因
      postData.quesInfo = JSON.stringify(this.formData.quesInfo);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        }).catch(error => {
          //会去调用出错的方法

        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        }).catch(error => {
          //会去调用出错的方法

        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        }).catch(error => {
          //会去调用出错的方法

        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.questionTableRef.clearSelection(); //清除选中
      this.$refs.questionTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      }).catch(error => {
        //会去调用出错的解决办法
      })
    },
    manInfoSuccess (data) { //JsonInfo,数据回传manInfo成功,更新数据
      this.formData.manInfo = data;
    },

    quesInfoSuccess (data) { //JsonInfo,数据回传quesInfo成功,更新数据
      this.formData.quesInfo = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {
    '$route.params.id': {
      handler: function () {
        var oldAsk = this.$store.state.LeftMenuState.activeAsk;
        if (this.$route.params.id) {
          this.formChooseDialogParam.transSelectValue = this.$route.params.id;
          this.direcParam.directName = this.$route.params.directName;
          this.direcParam.id = this.$route.params.id;
          this.tableParam.askType = this.$route.params.id;
          this.tableParam.key = '';
          this.tableParam.page = 1;
          this.tableDateRefresh();

        }
        else if (oldAsk.id) {
          this.formChooseDialogParam.transSelectValue = oldAsk.id;
          this.direcParam.directName = oldAsk.directName;
          this.direcParam.id = oldAsk.id;
          this.tableParam.askType = oldAsk.id;
          this.tableParam.key = '';
          this.tableParam.page = 1;
          this.tableDateRefresh();
        }

      },
      deep: true,
      immediate: true//立即执行
    }
  },

  filters: {
    sizeFilter (value) {
      if (value) {
        if (parseFloat(value / 1024).toFixed(2) > 512) {
          return parseFloat(value / 1024 / 1023).toFixed(2) + 'M'
        }
        else {
          return parseFloat(value / 1024).toFixed(2) + 'K'
        }
      }
      else {
        return "未知大小";
      }
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
